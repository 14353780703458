import { Dayjs } from 'dayjs';
import { v4 as uuid } from 'uuid';

export class Part {
  _id?: string;
  added?: boolean;
  addedBy?: string;
  addedByRepairer?: boolean;
  addedReason?: string;
  cancelledBy?: string;
  cancelReason?: string;
  createdAt?: Dayjs;
  delayedAt?: Dayjs;
  delayedBy?: string;
  delayedUntil?: Dayjs;
  delayReason?: string;
  description: string;
  discount?: string;
  discountAmount?: number;
  grouped?: boolean;
  invoicedAt?: Dayjs;
  invoicedBy?: string;
  invoiceNumber?: string;
  kitHeader?: boolean;
  locked?: boolean;
  lookupMatched?: boolean;
  lookupWarnings?: string[];
  net: string;
  ordinal?: number;
  partCode?: string;
  partId?: string;
  partNumber: string;
  partSplitChanges?: PartSplitChange[];
  possibleToSplit? = false;
  quantity = 1;
  quoteRequestId?: string;
  removed?: boolean;
  removedByRepairer?: boolean;
  removedReason?: string;
  rrp: string;
  splitChangeId?: string;
  status?: string;
  surcharge?: string;
  uniqueOrderId?: string;
  uniquePartId?: string;
  unit?: string;
  updated? = false;
  updatedAt?: Dayjs;

  constructor(part?: Part) {
    if (!part) {
      return this;
    }

    this.added = part.added || false;
    this.addedBy = part.addedBy || '';
    this.addedByRepairer = part.addedByRepairer || false;
    this.addedReason = part.addedReason || '';
    this.cancelledBy = part.cancelledBy || '';
    this.cancelReason = part.cancelReason || '';
    this.createdAt = part.createdAt;
    this.delayedAt = part.delayedAt;
    this.delayedBy = part.delayedBy || '';
    this.delayReason = part.delayReason || '';
    this.delayedUntil = part.delayedUntil;
    this.description = part.description || '';
    this.discount = part.discount || '0.00';
    this.discountAmount = part.discountAmount || 0;
    this.invoicedAt = part.invoicedAt;
    this.invoicedBy = part.invoicedBy || '';
    this.invoiceNumber = part.invoiceNumber || '';
    this.locked = part.locked || false;
    this.net = part.net || '1';
    this.ordinal = part.ordinal || 1;
    this.partCode = part.partCode || '';
    this.partId = part.partId || '';
    this.partNumber = part.partNumber || '';
    this.partSplitChanges = part.partSplitChanges || null;
    this.quantity = part.quantity = 1;
    this.quoteRequestId = part.quoteRequestId || '';
    this.removed = part.removed || false;
    this.removedByRepairer = part.removedByRepairer || false;
    this.removedReason = part.removedReason || '';
    this.rrp = part.rrp || '1';
    this.status = part.status || PartStatus.UNQUOTED;
    this.surcharge = part.surcharge || '0';
    this.uniqueOrderId = part.uniqueOrderId || '';
    this.uniquePartId = part.uniquePartId || uuid();
    this.unit = part.unit || '1';
    this.updated = part.updated || false;
    this.updatedAt = part.updatedAt;
  }
}

export interface PartsCalculation {
  subtotal: number;
  surcharge: number;
  total: number;
  vatCalculated: number;
  vatPercent: string;
  vatRate: number;
}

export interface PartsListChange {
  valid: boolean;
  valuesChanged: boolean;
}

export enum PartStatus {
  UNQUOTED = 'Unquoted',
  ON_ORDER = 'On Order',
  CONFIRMED = 'Confirmed',
  DELAYED = 'Delayed',
  SHIPPED = 'Shipped',
  DELIVERED = 'Delivered',
  CANCELLED = 'Cancelled',
  SPLIT = 'Split'
}

export class PartSplitChange {
  _id: string;
  active: boolean;
  createdAt: Dayjs;
  invoicedAt: Dayjs;
  invoiceNumber: string;
  quantity: number;
  rrp: number;
  partNumber: string;
  status: string;
  uniquePartId: string;
  updatedAt: Dayjs;

  constructor(data: any) {
    if (data._id) {
      this._id = data._id || '';
    }

    this.active = data.active || false;

    if (this.createdAt) {
      this.createdAt = data.createdAt || null;
    }

    if (data.invoiceNumber || data.invoicedAt) {
      this.invoicedAt = data.invoicedAt;
      this.invoiceNumber = data.invoiceNumber;
    }

    this.partNumber = data.partNumber || '';
    this.quantity = data.quantity || 1;
    this.rrp = data.rrp;
    this.status = data.status || PartStatus.CONFIRMED;
    this.uniquePartId = data.uniquePartId || '';

    if (data.updatedAt) {
      this.updatedAt = data.updatedAt || null;
    }
  }
}

<div class="product-list__table">
  <div class="empty" [hidden]="!loaded || dataSource?.data.length">
    <div class="empty-data-source">
      <div class="empty-data-source__svg">
        <img alt="Searching" width="108" height="108" src="../../../../assets/images/search.png"/>
      </div>
      <div class="empty-data-source__title">Sorry! No results found :(</div>
      <div class="empty-data-source__subtitle">
        We’re sorry we couldn’t find what you were looking for.<br/>Please try another search
      </div>
    </div>
  </div>

  <table #table mat-table
    class="mat-mdc-table__product-list"
    [class.mat-mdc-table__product-list--fixed-header]="isFixed$ | async"
    [dataSource]="dataSource" matSort
    (matSortChange)="onSortColumChange($event)"
  >

    <ng-container matColumnDef="scheme">
      <th *matHeaderCellDef mat-sort-header class="mat-sort-header__scheme">Scheme</th>
      <td mat-cell *matCellDef="let element;" class="product-list__{{element.scheme?.trim() | lowercase}}" [ngClass]="{'hovered': hoveredRow === element.link}">
        {{element.scheme}}
      </td>
    </ng-container>

    <ng-container matColumnDef="quoteNumber" *ngIf="displayedColumns?.includes('quoteNumber')">
      <th *matHeaderCellDef mat-sort-header class="mat-sort-header__quoteNumber">Quote No.</th>
      <td mat-cell *matCellDef="let element" class="quotes__created" [ngClass]="{'hovered': hoveredRow === element.link}">
        <span class="quotes__quote_No"
          matTooltipPosition="below"
          matTooltip="{{element.quoteNumber}}"
        >
          {{element.quoteNumber}}
        </span>
      </td>
    </ng-container>

    <ng-container matColumnDef="orderRef" *ngIf="displayedColumns?.includes('orderRef')">
      <th *matHeaderCellDef mat-sort-header class="mat-sort-header__orderRef">Order Ref</th>
      <td mat-cell *matCellDef="let element;" [ngClass]="{'hovered': hoveredRow === element.link}" class="product-list__created">
        {{ element.reference ? element.reference : 'TBC'}}
      </td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th *matHeaderCellDef mat-sort-header class="mat-sort-header__status">Status</th>
      <td mat-cell *matCellDef="let element;" class="product-list__cell-pad" [ngClass]="{'hovered': hoveredRow === element.link}">
        <span class="product-list__{{element.status?.trim() | lowercase}}">{{element.status | titlecase}}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="orderDate">
      <th *matHeaderCellDef mat-sort-header class="mat-sort-header__orderDate">{{routePath === 'submitted' ? 'Submitted' : 'Created'}}</th>
      <td mat-cell *matCellDef="let element;" [ngClass]="{'hovered': hoveredRow === element.link}">
        <span matTooltip={{tooltipDate(element.orderDate)}} [matTooltipPosition]="'right'" class="product-list__created">
          {{element.orderDate | dateFormatter | async}}
        </span>
      </td>
    </ng-container>

    <ng-container matColumnDef="customerDetails">
      <th *matHeaderCellDef mat-sort-header class="mat-sort-header__customerDetails">Customer Details</th>
      <td mat-cell *matCellDef="let element;" class="product-list__double-line" [ngClass]="{'hovered': hoveredRow === element.link}">
        <span>{{element.customerDetails}}</span><br />
        <span>{{element.repairerPostcode}}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="make">
      <th *matHeaderCellDef mat-sort-header class="mat-sort-header__make">Make - Registration</th>
      <td mat-cell *matCellDef="let element;" class="product-list__double-line--reversed" [ngClass]="{'hovered': hoveredRow === element.link}">
        <span>{{element.make}}</span><br>
        <span>{{element.registration}}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="quotedBy" *ngIf="displayedColumns?.includes('quotedBy')">
      <th *matHeaderCellDef mat-sort-header class="mat-sort-header__quotedBy">Submitted By</th>
      <td mat-cell *matCellDef="let element" [ngClass]="{'hovered': hoveredRow === element.link}">
        {{element.submittedBy}}
      </td>
    </ng-container>

    <ng-container matColumnDef="delayedParts" *ngIf="displayedColumns?.includes('delayedParts')">
      <th *matHeaderCellDef mat-sort-header class="mat-sort-header__delayedParts">Delayed Parts</th>
      <td mat-cell *matCellDef="let element;" [class.product-list__fir]="element.partsDelayed" [ngClass]="{'hovered': hoveredRow === element.link}">
        <span *ngIf="element.partsDelayed">Delayed Parts</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="partCount">
      <th *matHeaderCellDef mat-sort-header class="mat-sort-header__partCount">Item(s)</th>
      <td mat-cell *matCellDef="let element;" class="product-list__items" [ngClass]="{'hovered': hoveredRow === element.link}">{{element.partCount}}</td>
    </ng-container>

    <ng-container matColumnDef="net">
      <th *matHeaderCellDef mat-sort-header class="mat-mdc-header-cell--monetary mat-sort-header__net">Total Net</th>
      <td mat-cell *matCellDef="let element;" class="product-list__monetary" [ngClass]="{'hovered': hoveredRow === element.link}">{{element.net | currency:'GBP'}}</td>
    </ng-container>

    <ng-container matColumnDef="link" *ngIf="displayedColumns?.includes('link')">
      <th *matHeaderCellDef class="mat-mdc-header-cell--view-details mat-header__link">Details</th>
      <td mat-cell *matCellDef="let element;" [ngClass]="{'hovered': hoveredRow === element.link}">
        <button mat-button class="mat-mdc-button__primary product-list__view-details">
          View details
        </button>
      </td>
    </ng-container>

    <ng-container matColumnDef="details" *ngIf="displayedColumns?.includes('details')">
      <th *matHeaderCellDef class="mat-mdc-header-cell--view-details mat-header__details">Details</th>
      <td mat-cell *matCellDef="let element;" [ngClass]="{'hovered': hoveredRow === element.link}">
        <button mat-button class="mat-mdc-button__primary product-list__view-details">
          View details
        </button>
      </td>
    </ng-container>

    <tr
      mat-header-row
      *matHeaderRowDef="displayedColumns"
      [hidden]="loaded && !dataSource?.data.length"
      [style.left.px]="(isFixed$ | async) ? (headerLeft$ | async) : 0"
    ></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: displayedColumns"
      [id]="row.link"
      [ngClass]="{dimmed: !loaded}"
      [routerLink]="[displayedColumns.includes('link') ? '/orders/' : '/quotes/', row.link]"
      (mouseover)='mouseOverRow(row.link)'
      (mouseleave)='mouseLeaveRow()'
    ></tr>
  </table>
</div>

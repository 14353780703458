import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { SaveSnackbarComponent } from '../save-snackbar/save-snackbar.component';
import {
  FormControl,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { ReplaySubject, filter, takeUntil } from 'rxjs';
import { AppState } from '../../quotes/store/reducers';
import { User } from '../models/user.model';
import { createUser, selectedUser, updateUser } from '../store/users.actions';
import * as selector from '../store/users.selectors';
import { ManagementService } from '../services/management.service';
import { KeycloakService } from 'keycloak-angular';
import { KeycloakProfile } from 'keycloak-js';
import _ from 'lodash-es';
import { selectSideNavOpen, selectUserProfile, selectKeyCloakInstance } from '../../core/store/core.selectors';
import { setLoggedInUser } from '../../core/store/core.actions';
import { Role } from '../models/role.model';
import { EmailType } from '../models/emailType.model';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { UtilService } from '../../shared/services/util.service';
import * as coreSelector from '../../core/store/core.selectors';
import { DrawerService } from '../../core/services/drawer.service';

@Component({
  selector: 'app-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.scss']
})
export class UserFormComponent implements OnInit, OnDestroy {
  userObj: User;
  userForm: UntypedFormGroup;
  durationInSeconds = 5;
  accessSelected = false;
  assignableRoles: Role[] = [];
  creatingUser = false;
  editableUser: User;
  editingLoggedInUser: boolean;
  groupAccessSelected = false;
  groupAdminUser = false;
  groupSelected = false;
  horizontalPosition: MatSnackBarHorizontalPosition = 'end';
  listedUserSelected = false;
  loggedInUser: User;
  selectedUser: User;
  sideBarOpen = false;
  siteAccessSelected = false;
  siteAdminUser = false;
  updatingUser = false;
  userProfile: KeycloakProfile;
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  private onDestroy$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(
    private snackbar: MatSnackBar,
    private store: Store<AppState>,
    private formBuilder: UntypedFormBuilder,
    private managementService: ManagementService,
    private readonly keycloakService: KeycloakService,
    private drawerService: DrawerService,
    private utilService: UtilService
  ) {}

  get copyOf(): any {
    return this.utilService.getCopyOf;
  }

  get form(): any {
    return this.userForm.controls;
  }

  get loggedInUserId(): string {
    return this.keycloakService.getKeycloakInstance().subject;
  }

  get orderNotifications(): EmailType[] {
    return this.editableUser.emailTypes.filter(
      (t) => t.name === 'ORDER_RECEIVED' || t.name === 'ORDER_STATUS_UPDATED'
    );
  }

  get sit() {
    return this.userForm.get('siting') as UntypedFormArray;
  }

  get quoteNotifications(): EmailType[] {
    return this.editableUser.emailTypes.filter(
      (t) => t.name === 'QUOTE_RECEIVED' || t.name === 'ADDITIONAL_PARTS_RECEIVED'
    );
  }

  ngOnInit(): void {
    this.editableUser = new User({});
    this.initializeForm();
    this.collectUserData();

    this.loadUserForm();
    this.store
      .pipe(
        select(selectSideNavOpen),
        filter((data) => typeof data === 'boolean'),
        takeUntil(this.onDestroy$)
      )
      .subscribe((open) => {
        this.sideBarOpen = open;
        if (this.sideBarOpen) {
          if (!(this.listedUserSelected || this.creatingUser)) {
            this.setupLoggedInUser();
          }
        } else {
          this.store.dispatch(selectedUser({ user: null }));
          this.listedUserSelected = false;
          if (this.loggedInUser) {
            this.editableUser = this.copyOf(this.loggedInUser);
            this.editableUser.virtualGroups = this.editableUser.virtualGroups || [];
          }
        }
      });
    this.store.pipe(select(coreSelector.selectLoggedInUser)).subscribe((loggedInUser) => {
      if (loggedInUser) {
        this.loggedInUser = loggedInUser;
      }
    });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

  checkEmailAvailability(): void {
    if (!this.form || !this.creatingUser) {
      return;
    }

    const email = { email: this.form.email.value };
    this.managementService.checkEmailAvailability(email).subscribe({
      next: (response) => {
        if (response) {
          this.form.email.setErrors({ usernameExists: true });
        }
      },
      error: (e) => console.error(e)
    });
  }

  checkLoggedInUser(): void {
    this.editingLoggedInUser = !this.creatingUser && this.editableUser.id === this.loggedInUserId;
  }

  closeSideBar(): void {
    this.drawerService.toggle(false);
  }

  createUser(): void {
    const newUser = { id: this.editableUser.id } as User;
    const excludeProp = (prop: string, item: any) => {
      const result = {};
      Object.keys(item).forEach((key) => {
        if (key !== prop) {
          result[key] = item[key];
        }
      });
      return result;
    };

    try {
      Object.keys(this.form).forEach((key) => {
        const control = this.form[key];
        if (!(control.validator || control.value)) {
          return;
        }

        switch (key) {
          case 'roles':
            newUser.roles = [].concat(
              excludeProp(
                'checked',
                this.editableUser.roles.find((role) => role.checked)
              )
            );
            newUser.roles.map((role) => {
              role.keep = true;
            });
            break;

          case 'sites':
            newUser.sites = this.editableUser.sites
              .filter((site) => site.checked)
              .map((site) => ({
                centreCode: site.centreCode,
                id: site.id,
                keep: true,
                name: site.name,
                path: site.path
              }));
            break;

          case 'virtualGroup':
            (newUser.virtualGroups as any) = [].concat(
              this.loggedInUser.virtualGroups
                .filter((v) => v.name === control.value)
                .map((virtualGroup: any) => ({ _id: virtualGroup._id }))
            );
            break;

          default:
            newUser[key] = control.value;
        }
      });

      newUser.resetPassword = true;
      newUser.sites = newUser.sites || [];
      newUser.virtualGroups = newUser.virtualGroups || [];
      if(newUser.siting === undefined) {
        newUser.siting = [];
      }
      if(newUser.enabled === undefined) {
        newUser.enabled = true;
      }
      this.store.dispatch(createUser({ userCreate: newUser }));
      this.closeSideBar();
    } catch (err) {
      console.log('Error occurred while creating user at user-form component. Error: ', err);
    }
  }

  getEmailTypeName(type: string): string {
    switch (type) {
      case 'ADDITIONAL_PARTS_RECEIVED':
        return 'Additonal part received';
      case 'ORDER_RECEIVED':
        return 'New order';
      case 'ORDER_STATUS_UPDATED':
        return 'Order status updates';
      case 'QUOTE_RECEIVED':
        return 'New quotes';
      default:
        return '';
    }
  }

  initializeForm(): void {
    this.userForm = this.formBuilder.group({
      firstName: new UntypedFormControl('', [
        Validators.pattern('^[a-zA-Z]*$'),
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(255)
      ]),
      lastName: new UntypedFormControl('', [
        Validators.pattern('^[a-zA-Z]*$'),
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(255)
      ]),
      access: this.createFormControl(),
      enabled: this.createFormControl(true),
      email: ['', [Validators.required, Validators.email]],
      siting: new UntypedFormArray([]),
      resetPassword: false,
      allEmailTypes: false,
      noEmailTypes: false,
      orderTypes: false,
      quoteTypes: false,
      roles: this.createFormControl(),
      sites: this.createFormControl(),
      virtualGroup: this.createFormControl(true)
    });
  }

  isCheckedRole(role: string): boolean {
    if (!this.editableUser?.roles?.length || !this.loggedInUser?.roles?.length) {
      return;
    }

    return (
      this.editableUser.roles.find((r) =>
        role === 'site_admin' ? ['group_admin', 'site_admin'].includes(r.name) : r.name === role
      ) || {}
    ).checked;
  }

  isCheckedSite(site: string): boolean {
    return this.editableUser.sites.find((s) => s.name === site).checked;
  }

  isUserEnabled(): boolean {
    if (this.creatingUser) {
      return true;
    }
    return !this.groupAdminUser || (this.groupAdminUser && this.editingLoggedInUser);
  }

  markGroupChecked(email?: string): void {
    let selectedVirtualGroup = this.editableUser.virtualGroups.find((vg) => vg.email === email);
    if (!selectedVirtualGroup) {
      this.editableUser.virtualGroups.forEach((vg) => (vg.checked = false));
      // Select the first virtual group
      selectedVirtualGroup = this.editableUser.virtualGroups.at(0);
      this.form.virtualGroup.setValue(selectedVirtualGroup.name);
    }
    selectedVirtualGroup.checked = true;
    this.editableUser.sites = this.loggedInUser.sites.filter((site) =>
      selectedVirtualGroup.centreCodes.includes(site.centreCode)
    );
  }

  onAccessSelectionChange(access: string): void {
    this.groupAccessSelected = access === 'Group access';
    this.siteAccessSelected = this.accessSelected && !this.groupAccessSelected;
    this.setUserAccess(access);
  }

  onEmailPreferenceChange(event: MatCheckboxChange, type: any): void {
    switch (type.name) {
      case 'quoteTypes':
        this.quoteNotifications.forEach((emailType) => (emailType.checked = event.checked));
        break;

      case 'orderTypes':
        this.orderNotifications.forEach((emailType) => (emailType.checked = event.checked));
        break;

      case 'allEmailTypes':
      case 'noEmailTypes':
        this.editableUser.emailTypes.forEach(
          (emailType) => (emailType.checked = type.name === 'allEmailTypes' ? event.checked : !event.checked)
        );
        break;

      default:
        this.editableUser.emailTypes.find((emailType) => emailType.name === type.name).checked = event.checked;
    }
    this.updateFormEmailPreferences();
  }

  onRoleSelectionChange(role: string): void {
    this.editableUser.roles.forEach((r) => {
      r.checked = r.name === role;
    });

    this.form.roles.setValue(this.editableUser.roles.filter((r) => r.checked).map((r) => r.name));
  }

  onSiteSelectionChange(checkbox: MatCheckboxChange, centreCode: string): void {
    const targetSite = this.editableUser.sites.find((site) => site.centreCode === centreCode);
    targetSite.checked = checkbox.checked;
    this.form.sites.setValue(this.editableUser.sites.filter((site) => site.checked).map((s) => s.centreCode));
  }

  openSnackBar(type: any): void {
    const durationInSeconds = 5;
    this.snackbar.openFromComponent(SaveSnackbarComponent, {
      duration: durationInSeconds * 1000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      data: type
    });
  }

  patchForm(): void {
    if (this.creatingUser) {
      return;
    }

    this.userForm.patchValue({
      awaiting: this.editableUser.awaiting,
      email: this.editableUser.email,
      enabled: this.editableUser.enabled,
      firstName: this.editableUser.firstName,
      lastName: this.editableUser.lastName,
      resetPassword: this.editableUser.resetPassword,
      roles: this.editableUser.roles,
      sites: this.editableUser.sites
    });
  }

  shouldDisable(type: string, value = ''): boolean {
    switch (type) {
      case 'role':
        return (
          this.editingLoggedInUser ||
          value.includes('admin') ||
          !this.editableUser.roles.find((role) => role.name === value)
        );

      case 'access':
      case 'group-selector':
      case 'site-selector':
        return this.creatingUser ? false : !this.groupAdminUser || (this.groupAdminUser && this.editingLoggedInUser);
    }
  }

  shouldSubmitForm(): boolean {
    if (!this.userForm.valid) {
      return false;
    }

    this.editableUser.firstName = this.form.firstName.value;
    this.editableUser.lastName = this.form.lastName.value;

    // Check if user detail has been updated
    const comparableFields = [
      'emailTypes',
      'enabled',
      'firstName',
      'lastName',
      'resetPassword',
      'roles',
      'sites',
      'virtualGroups'
    ];

    return comparableFields.some((field) => {
      if (Array.isArray(this.selectedUser[field])) {
        const original = this.selectedUser[field]
          .filter((val: any) => val.checked)
          .map((val: any) => val.name)
          .sort()
          .join('');

        const updated = this.editableUser[field]
          .filter((val: any) => val.checked)
          .map((val: any) => val.name)
          .sort()
          .join('');

        return updated !== original;
      }
      return this.editableUser[field] !== this.selectedUser[field];
    });
  }

  toggleEnableUser(): void {
    this.editableUser.enabled = this.form.enabled.value === true;
  }

  toggleResetPassword(checkbox: MatCheckboxChange): void {
    this.editableUser.resetPassword = checkbox.checked;
  }

  updateUser(): void {
    const updatedUser = { id: this.editableUser.id } as User;
    updatedUser.email = this.form.email.value;
    updatedUser.emailTypes = this.editableUser.emailTypes.filter((type) => type.checked);
    updatedUser.enabled = this.form.enabled.value;
    updatedUser.firstName = this.form.firstName.value;
    updatedUser.lastName = this.form.lastName.value;
    updatedUser.resetPassword = this.form.resetPassword.value;
    updatedUser.roles = this.editableUser.roles.map((r) => {
      r.keep = r.checked;
      const { checked, ...role } = r;
      return role;
    });

    if (this.groupAccessSelected) {
      updatedUser.virtualGroups = this.form.virtualGroup.value;
    } else {
      updatedUser.sites = this.editableUser.sites
        .map((s) => {
          s.keep = s.checked;
          const { checked, ...site } = s;
          return site;
        })
        .filter((s) => s.keep);
    }

    this.store.dispatch(updateUser({ userId: updatedUser.id, userUpdate: updatedUser }));
    this.updatingUser = true;
  }

  private collectUserData(): void {
    this.setupLoggedInUser();

    // Load logged-in user profile
    this.loadUserProfile();

    // Load selected user from list
    // or updated user
    this.store
      .pipe(
        select(selector.selectedUser),
        filter((data) => !!data)
      )
      .subscribe((user) => {
        const { assignableRoles, ...editableUser } = user as any;

        // Add to assignable roles
        editableUser.roles.forEach((role: Role) => {
          if (!this.assignableRoles.map((r) => r.name).includes(role.name)) {
            this.assignableRoles.push(this.copyOf(role));
          }
          role.checked = role.keep;
        });

        this.editableUser = editableUser;
        this.editableUser.virtualGroups = editableUser.virtualGroups || [];
        this.listedUserSelected = true;
        this.presetUser();
        if (this.updatingUser) {
          // User has just been updated
          this.updatingUser = false;
          if (this.editingLoggedInUser) {
            ['firstName', 'lastName'].forEach(
              (key) => (this.userProfile[key] = this.loggedInUser[key] = this.editableUser[key])
            );
            this.loggedInUser.emailTypes = this.editableUser.emailTypes;
          }
        }
        this.loadForm();
      });

    // Identify logged-in user role
    this.store
      .pipe(
        select(selectKeyCloakInstance),
        filter((data) => !!data),
        takeUntil(this.onDestroy$)
      )
      .subscribe((keyCloakInstance) => {
        const userRoles = keyCloakInstance?.realm_access.roles;
        this.groupAdminUser = userRoles.includes('site_admin') || userRoles.includes('group_admin');
        this.siteAdminUser = userRoles.includes('site_admin');
      });
  }

  private createFormControl(optional = false): FormControl {
    if (optional) {
      return new FormControl();
    }
    return new FormControl(null, Validators.required);
  }

  private loadForm(): void {
    this.setUserAccess();
    // Keep copy of form data, so we know if anything has changed
    this.selectedUser = this.copyOf(this.editableUser);
    if (!this.creatingUser) {
      this.patchForm();
    }
    this.updateFormEmailPreferences();
  }

  private loadUserForm(): void {
    // Identify create or edit mode
    this.store.pipe(select(selector.selectIsCreateUser), takeUntil(this.onDestroy$)).subscribe((response) => {
      this.creatingUser = response;
      if (this.creatingUser && this.loggedInUser) {
        // Create new user
        this.initializeForm();
        this.editableUser = this.copyOf(this.loggedInUser);
        this.editableUser.virtualGroups = this.editableUser.virtualGroups || [];
        this.presetUser();
        this.loadForm();
      }
    });
  }

  private loadUserProfile(): void {
    this.store
      .pipe(
        select(selectUserProfile),
        filter((data) => !this.utilService.isEmpty(data)),
        takeUntil(this.onDestroy$)
      )
      .subscribe((userProfile) => {
        const { attributes, emailVerified, userProfileMetadata, username, ...profile } = userProfile as any;
        this.userProfile = profile;
      });
  }

  private presetUser(): void {
    this.checkLoggedInUser();
    this.presetUserRoles();
    this.presetUserSites();
  }

  private presetUserRoles(): void {
    this.assignableRoles?.forEach((role) => {
      let userRole = this.editableUser.roles.find((r) => r.name === role.name);
      if (!userRole) {
        userRole = this.copyOf(role);
        userRole.keep = false;
        this.editableUser.roles.push(userRole);
      }
    });

    const currentRole = this.creatingUser
      ? this.editableUser.roles.find((r) => r.name === 'site_user')
      : this.editableUser.roles.find((r) => r.keep) || this.editableUser.roles.at(0);

    this.editableUser.roles.forEach((role) => {
      role.checked = role === currentRole;
    });
    this.form.roles.setValue(currentRole.name);
  }

  private presetUserSites(): void {
    this.editableUser.sites.forEach((site) => {
      site.checked = this.creatingUser ? false : this.editingLoggedInUser ? true : site.keep;
    });
  }

  private resetFormControl(formControl: FormControl): void {
    formControl.reset();
    formControl.removeValidators(Validators.required);
    formControl.updateValueAndValidity();
  }

  private setupLoggedInUser(): void {
    if (this.loggedInUser) {
      this.presetUser();
      this.loadForm();
      return;
    }

    this.managementService
      .getUser(this.loggedInUserId)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe({
        next: (loggedInUser) => {
          if (this.listedUserSelected && !this.creatingUser) {
            return;
          }
          this.loggedInUser = loggedInUser;
          this.assignableRoles = loggedInUser.assignableRoles;
          this.store.dispatch(setLoggedInUser({ loggedInUser }));
          this.editableUser = this.copyOf(loggedInUser);
          this.editableUser.virtualGroups = this.editableUser.virtualGroups || [];
          this.presetUser();
          this.loadForm();
        },
        error: (err) => this.snackbar.open(err)
      });
  }

  private setUserAccess(access = ''): void {
    if (access === 'Group access') {
      this.groupAccessSelected = true;
    } else if (access === 'Site access') {
      this.siteAccessSelected = true;
    } else {
      this.groupAccessSelected = !!this.editableUser.virtualGroups?.length;
    }
    this.siteAccessSelected = !this.groupAccessSelected;
    this.form.access.setValue(`${this.groupAccessSelected ? 'Group' : 'Site'} access`);
    this.accessSelected = true;

    const sitesFormControl = this.form.sites;
    const virtualGroupFormControl = this.form.virtualGroup;
    if (this.groupAccessSelected) {
      this.markGroupChecked();
      this.resetFormControl(sitesFormControl as FormControl);
      virtualGroupFormControl.addValidators(Validators.required);
      virtualGroupFormControl.updateValueAndValidity();
    } else {
      this.editableUser.virtualGroups.forEach((g) => (g.checked = false));
      this.resetFormControl(virtualGroupFormControl as FormControl);
      sitesFormControl.addValidators(Validators.required);
      sitesFormControl.updateValueAndValidity();
      if (this.creatingUser) {
        this.editableUser.sites = this.copyOf(this.loggedInUser.sites);
      }
    }

    this.groupSelected = this.editableUser.virtualGroups.some((g) => g.checked);
  }

  private updateFormEmailPreferences(): void {
    const notifyOnAll = this.editableUser.emailTypes.every((type) => type.checked);
    const notifyNone = this.editableUser.emailTypes.every((type) => !type.checked);
    const notifyOnQuotes = this.quoteNotifications.every((type) => type.checked);
    const notifyOnOrders = this.orderNotifications.every((type) => type.checked);

    this.userForm.patchValue({ allEmailTypes: notifyOnAll });
    this.userForm.patchValue({ noEmailTypes: notifyNone });

    // Quote and Order email preferences
    this.userForm.patchValue({ quoteTypes: notifyOnQuotes });
    this.userForm.patchValue({ orderTypes: notifyOnOrders });
  }
}

/* eslint-disable @typescript-eslint/member-ordering */
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { UrlService } from '../../core/services/url.service';
import { Part, PartsListChange } from '../../parts/models/part.model';
import { Quote, QuoteScheme } from '../models/quote.model';
import { Vehicle } from '../models/vehicle.model';
import { PagedResult, QueryParams } from '../../shared/components/paginator/paging.consts';
import { PaginationService } from '../../shared/services/pagination.service';
import { CAppPart, ERoundingScheme } from '../../parts/models/parts-list.model';
import { RoundingService } from '../../parts/services/rounding.service';
import dayjs from 'dayjs';
import { UserService } from '../../user.service';
import { CPart, CTLAPart, TPartUpdate } from '../../parts/models/parts-list.model';

export interface DiscountLookupPart {
  partId?: string; // potentially not populated when adding part
  partNumber: string;
}

// IMPORTED FROM COMP LIB
export interface AddPart {
  partNumber: string;
  description: string;
  quantity: number;
  rrp: string;
  surcharge?: string;
  unit: string;
  net: string;
  reason: string;
  discount?: string;
  lookupMatched?: boolean;
  lookupWarnings?: string[];
}

interface DeletePart {
  id: string;
  reason: string;
}

interface PartUpdate {
  uniquePartId: string; // unique part id
  update: Record<TPartUpdate, string | number | boolean | string[]>;
}

interface VehicleDetailsUpdate {
  colour: string;
  make: string;
  model: string;
  registration: string;
  vin: string;
}

@Injectable()
export class QuotesService {
  lockStatus = new BehaviorSubject<boolean>(false);
  quote = new BehaviorSubject<Quote>(null);
  quotes: Quote[];
  resultChanged$ = new Subject<PagedResult>();
  saved$ = new Subject<Quote>(); // response quote when saved
  savePartUpdate = new BehaviorSubject<boolean>(null);
  submitState = new BehaviorSubject<boolean>(false);
  valuesChanged = new BehaviorSubject<PartsListChange>(null);

  // imported from lib quotes service
  public partCount$ = new BehaviorSubject<number>(0);

  private _lockedQuote$ = new BehaviorSubject<boolean>(false);
  private _vehicleConfirmed$ = new BehaviorSubject<boolean>(false);
  private originalParts = null;

  public readonly addPart$: Subject<AddPart> = new Subject();
  public readonly appVersionReceived$: Subject<string> = new Subject();
  public readonly cancel$: Subject<boolean> = new Subject();
  public readonly cancelDelivery$: Subject<string> = new Subject();
  public readonly confirmVehicleDetails$: Subject<VehicleDetailsUpdate> = new Subject();
  public readonly createReturn$: Subject<string> = new Subject();
  public readonly delayPart$: Subject<string> = new Subject();
  public readonly deletePart$: Subject<DeletePart> = new Subject();
  public readonly lockedQuote$ = this._lockedQuote$.asObservable(); // returning as observable ensures value is not unintentionally null
  public readonly saveQuote$: Subject<string> = new Subject();
  public readonly searchTerm$: Subject<string> = new Subject();
  public readonly splitPart$: Subject<string> = new Subject();
  public readonly submitQuote$: Subject<string> = new Subject();
  public readonly updatePart$: Subject<PartUpdate> = new Subject();
  public readonly updateVehicleDetails$: Subject<VehicleDetailsUpdate> = new Subject();
  public readonly reAddRemovePart$: Subject<string> = new Subject();

  public readonly vehicleConfirmed$ = this._vehicleConfirmed$.asObservable();

  public canEditParts = false;
  public canSave = false;
  public errors = false; // TODO: Maybe store errors here
  public originalJSON = ''; // original parts before being saved, reloaded etc.
  public vehicleConfirmed = false;
  protected _parts: (CPart | CTLAPart | CAppPart)[] = [];

  private appVersion = '';
  // ==========

  private quoteScheme = '';
  private savingQuote = false;

  constructor(
    private http: HttpClient,
    private readonly paginationService: PaginationService,
    private roundingService: RoundingService,
    private readonly urlService: UrlService,
    private readonly userService: UserService
  ) {
    this.appVersionReceived$.subscribe((version: string) => (this.appVersion = version));
  }

  get parts(): (CPart | CTLAPart | CAppPart)[] {
    return this._parts;
  }

  confirmVehicle(id: string): Observable<Vehicle> {
    return this.http
      .post(this.urlService.getUrl('VEHICLE_CONFIRM', { ':id': id }), {})
      .pipe(map((response: Vehicle) => response));
  }

  // USING PARTS LOOKUP
  getDiscount(part: Record<string, any>, siteCode: string): Observable<any> {
    // TODO: Error handling
    part.partNumber = part.partNumber.replace(/\s/g, '');
    return this.http.post(this.urlService.getUrl('GET_PART_DISCOUNTS'), { part, siteCode }).pipe(
      map((res: any) => ({
        values: res,
        id: part.uniquePartId
      }))
    );
  }

  getQuote(id: string): Observable<Quote> {
    return this.http.get(this.urlService.getUrl('QUOTE_BY_ID', { ':id': id })).pipe(
      map((response: Quote) => {
        // set quantity of parts group headers
        response.parts.map((part) => {
          // if part group header
          if (part.kitHeader) {
            const otherParts = response.parts.filter(
              (p) => p.partCode === part.partCode && p.uniquePartId !== part.uniquePartId
            );
            const quantity = otherParts.map((p) => p.quantity).reduce((a, b) => a + b, 0);
            if (part.quantity !== quantity) {
              part.quantity = quantity;
            }
          }
          // round net
          part.net = this.roundingService.roundValue(
            +part.net,
            this.userService.isSubscribed() ? ERoundingScheme.ROUND_UP : ERoundingScheme.AUTO
          );
          return part;
        });

        // Keep current quote scheme
        this.quoteScheme = response.quoteScheme;
        // Keep a copy of parts to allow checking for changes
        this.keepPartsToCheckForChanges(response.parts);

        // order parts based on created at date
        response.parts.sort((a, b) => (dayjs(a.createdAt).isAfter(dayjs(b.createdAt)) ? 1 : -1));
        return response;
      })
    );
  }

  getQuotes(query?: QueryParams): void {
    const currentPage = query.page;
    if (!query) {
      // Page is loading; use defaults
      query = new QueryParams();
    }

    const entries = Object.entries(query);
    const keys = entries.map((ent) => ent.at(0));
    const values = entries.map((ent) => ent.at(1));
    const params = new HttpParams()
      .set(keys.at(0), values.at(0))
      .set(keys.at(1), values.at(1))
      .set(keys.at(2), values.at(2))
      .set(keys.at(3), values.at(3))
      .set(keys.at(4), values.at(4))
      .set(keys.at(5), values.at(5))
      .set(keys.at(6), values.at(6));

    this.http
      .get(this.urlService.getUrl('QUOTES'), { params })
      .pipe(
        map((response: PagedResult) => {
          response.page = currentPage;
          this.paginationService.onResultChange(response);
          this.resultChanged$.next(response);
        })
      )
      .subscribe();
  }

  getSasToken(imageName: string): Observable<any> {
    return this.http
      .get(this.urlService.getUrl('GET_SAS_TOKEN', { ':imageName': imageName }))
      .pipe(map((response: any) => response));
  }

  hasChanged(updatedParts: (CPart | CTLAPart | CAppPart)[]): boolean {
    if (!this.originalParts) {
      return false;
    }

    const additionalPart = updatedParts.find((up) => up.added && !up.createdAt);
    if (additionalPart) {
      return true;
    }

    const numericProps = (this.quoteScheme === QuoteScheme.APP ? ['appDiscount'] : []).concat([
      'discount',
      'net',
      'quantity',
      'rrp',
      'surcharge',
      'unit'
    ]);

    const tolerance = 0.01;
    const valuesChanged = updatedParts.some((updatedPart) => {
      const originalPart = this.originalParts.find((p: Part) => p.uniquePartId === updatedPart.uniquePartId);
      const changesFound = Object.keys(originalPart).some((prop) => {
        const updatedValue = updatedPart[prop];
        const originalValue = originalPart[prop];
        const numeric = numericProps.includes(prop);
        let hasChanged = false;
        let truncatedOriginalValue = null;
        let truncatedUpdatedValue = null;

        if (numeric) {
          if (!updatedPart.kitHeader) {
            // ignore kit headers as the user can not manually change numeric values
            // Value differences that fall within a tolerance could probably be considered unchanged
            truncatedOriginalValue = this.truncateNumber(originalValue);
            truncatedUpdatedValue = this.truncateNumber(updatedValue);
            const difference = truncatedOriginalValue - truncatedUpdatedValue;
            hasChanged = Math.abs(difference) > tolerance;
          }
        } else {
          hasChanged = (updatedValue || '').toString() !== (originalValue || '').toString();
        }

        if (hasChanged) {
          console.log(
            `${prop.toUpperCase()} was updated from ${originalValue} to ${updatedValue}${
              numeric && (+originalValue !== truncatedOriginalValue || +updatedValue !== truncatedUpdatedValue)
                ? ' (' + truncatedOriginalValue + ' to ' + truncatedUpdatedValue + ')'
                : ''
            } on part number ${updatedPart.partNumber}`
          );
        }
        return hasChanged;
      });
      return changesFound;
    });

    return valuesChanged;
  }

  keepPartsToCheckForChanges(parts: Part[]): void {
    this.originalParts = parts.map((p: any) =>
      Object.assign(
        this.quoteScheme === QuoteScheme.APP
          ? {
              appDiscount: p.appDiscount
            }
          : {},
        {
          description: p.description,
          discount: p.discount || '',
          net: p.net || '',
          partNumber: p.partNumber,
          quantity: p.quantity || '',
          removed: p.removed,
          rrp: p.rrp || '',
          surcharge: p.surcharge || 0,
          uniquePartId: p.uniquePartId,
          unit: p.unit || ''
        }
      )
    );
  }

  saveQuote(id: string, data: any): Observable<Quote> {
    // some values are displayed as strings but saved as numbers
    const { parts } = data;
    const updatedParts = parts.map((part: Part) => ({
      ...part,
      discount: +part.discount,
      net: +part.net,
      rrp: +part.rrp,
      unit: +part.unit,
      surcharge: +part.surcharge
    }));
    const dataToSave = { ...data, parts: updatedParts };

    return this.http.patch(this.urlService.getUrl('SAVE_QUOTE', { ':quoteId': id }), dataToSave).pipe(
      map((response: Quote) => {
        this.savingQuote = false;
        this.keepPartsToCheckForChanges(response.parts);
        response.parts.sort((a, b) => (dayjs(a.createdAt).isAfter(dayjs(b.createdAt)) ? 1 : -1));
        return response;
      }),
      tap((response: Quote) => this.saved$.next(response))
    );
  }

  setLockStatus(value: boolean): void {
    this.lockStatus.next(value);
  }

  setSavePartUpdate(value: boolean): void {
    this.savePartUpdate.next(value);
  }

  setValuesChanged(change: PartsListChange): void {
    this.valuesChanged.next(change);
  }

  triggerSave(id: string): void {
    if (!this.savingQuote) {
      this.savingQuote = true;
      this.saveQuote$.next(id);
    }
  }

  truncateNumber(value: number, decimalPlaces = 3): number {
    return +(+value).toFixed(decimalPlaces + 1).slice(0, -1);
  }

  updateLock(estimateId: string, status: any): Observable<Quote> {
    return this.http
      .patch(this.urlService.getUrl('UPDATE_LOCK', { ':estimateId': estimateId }), { status })
      .pipe(map((response: Quote) => response));
  }

  updateVehicle(id: string, changes: Partial<Vehicle>): Observable<Vehicle> {
    return this.http
      .patch(this.urlService.getUrl('VEHICLE_UPDATE', { ':id': id }), changes)
      .pipe(map((response: Vehicle) => response));
  }
  reAddRemovedPart(partID): void {
    this.reAddRemovePart$.next(partID);
  }

  /*
    FUNCS MIGRATED FROM COMPONENT LIB
  */
  addPart(part: AddPart): void {
    this.addPart$.next(part);
  }

  calculateDiscount(part: AddPart): string {
    // if rrp or net is 0 avoid dividing by 0
    return Number(part.net) === 0 || Number(part.rrp) === 0
      ? '0'
      : this.roundingService.roundValue(100 - +part.net / +part.quantity / (+part.rrp / 100));
  }

  calculateNetValue(part: AddPart): string {
    if (part.unit && part.quantity) {
      return this.roundingService.roundValue(+part.unit * +part.quantity);
    } else {
      return '0';
    }
  }

  calculateUnitValue(part: AddPart): string {
    return this.roundingService.roundValue(+part.net / +part.quantity);
  }

  cancelDelivery(_id: string): void {
    this.cancelDelivery$.next(_id);
  }

  confirmVehicleDetails(value: VehicleDetailsUpdate): void {
    this.confirmVehicleDetails$.next(value);
  }

  createReturn(_id: string): void {
    this.createReturn$.next(_id);
  }

  decrementQuantity(quantity: number): number {
    if (!quantity) {
      quantity = 1;
    } else {
      --quantity;
    }

    return quantity;
  }

  delayPart(_id: string): void {
    this.delayPart$.next(_id);
  }

  deletePart(uniquePartId: string, reason: string): void {
    this.deletePart$.next({ id: uniquePartId, reason });
  }

  getAppVersion(): string {
    return this.appVersion;
  }

  incrementQuantity(quantity: number): number {
    if (!quantity) {
      quantity = 1;
    }
    ++quantity;
    return quantity;
  }

  isValidInput(event: KeyboardEvent): boolean {
    const validCharacters = /^[0-9A-Z]{1,}$/;
    return (event.key.trim() || '').toUpperCase().match(validCharacters) !== null;
  }

  removeSpecialCharacters(element: any, text: string | ClipboardEvent): void {
    if (typeof text !== 'string') {
      text = (text as ClipboardEvent).clipboardData?.getData('Text') || '';
    }

    const delayInMilliseconds = 100;
    const timer = setTimeout(() => {
      clearTimeout(timer);
      element._value = (text as string)
        .trim()
        .split('')
        .filter((char) => this.isValidInput({ key: char } as KeyboardEvent))
        .join('')
        .toUpperCase();
    }, delayInMilliseconds);
  }

  setVehicleConfirmed(confirmed: boolean): void {
    this.vehicleConfirmed = confirmed;
    this._vehicleConfirmed$.next(confirmed);
  }

  splitPart(_id: string): void {
    this.splitPart$.next(_id);
  }

  submitQuote(_id: string): void {
    this.submitQuote$.next(_id);
  }

  updateLockedState(locked: boolean): void {
    this._lockedQuote$.next(locked);
  }

  updatePart(value: PartUpdate): void {
    this.updatePart$.next(value);
  }

  updatedParts(parts: (CPart | CTLAPart | CAppPart)[]): void {
    this._parts = parts;
  }

  updateSearch(value: string): void {
    this.searchTerm$.next(value);
  }

  updateVehicleDetails(vehicle: VehicleDetailsUpdate): void {
    this.updateVehicleDetails$.next(vehicle);
  }
  /*
    =================================
  */
}

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { UrlService } from '../../core/services/url.service';
import { isCreatingPartSplit } from '../store/orders.selectors';
import { Store } from '@ngrx/store';
import { PartStatus, PartSplitChange } from '../../parts/models/part.model';
import dayjs from 'dayjs';
import { UtilService } from '../../shared/services/util.service';
import { PagedResult, QueryParams } from '../../shared/components/paginator/paging.consts';
import { PaginationService } from '../../shared/services/pagination.service';
import { Order } from '../models/order.model';
import { CPart, ERoundingScheme } from '../../parts/models/parts-list.model';
import { UserService } from '../../user.service';
import { RoundingService } from '../../parts/services/rounding.service';

@Injectable()
export class OrdersService {
  public orderedPartSplit: Subject<any> = new Subject();
  public selectedParts: CPart[] = [];
  public orderDetails?: {
    _id: string;
    repairerName: string;
  };

  allowSplit: boolean;
  resultChanged$ = new Subject<PagedResult>();

  private creatingPartSplit$: Observable<boolean>;

  constructor(
    private http: HttpClient,
    private readonly paginationService: PaginationService,
    private readonly urlService: UrlService,
    private roundingService: RoundingService,
    private store: Store,
    private userService: UserService,
    private utilService: UtilService
  ) {}

  createPartsSplit(action: any): void {
    this.creatingPartSplit$ = this.store.select(isCreatingPartSplit);
    this.creatingPartSplit$.subscribe({
      next: (creatingPartSplit: boolean) => {
        if (creatingPartSplit) {
          this.allowSplit = creatingPartSplit;
        }
      }
    });
    const { partSplitData } = action;
    if (partSplitData) {
      const partsShipped = new PartSplitChange(
        Object.assign(
          {
            active: true,
            partNumber: partSplitData?.data?.part?.partNumber,
            quantity: partSplitData?.data?.quantityShipped,
            rrp: partSplitData?.data?.part?.rrp,
            status: PartStatus.SHIPPED,
            uniquePartId: partSplitData?.data?.part?.uniquePartId,
            unit: partSplitData?.data?.part?.unit
          },
          { splitChangeId: partSplitData?.data?.part?.splitChangeId },
          partSplitData?.invoiceNumberCheck
            ? {
                invoicedAt: partSplitData?.invoicedAt,
                invoiceNumber: partSplitData?.invoiceNumberCheck
              }
            : {}
        )
      );

      const splitChanges = [partsShipped];
      const { invoicedAt, invoiceNumber, ...partsRemaining } = this.utilService.getCopyOf(partsShipped);
      partsRemaining.quantity = partSplitData.data.quantityRemaining;
      if (partSplitData.remainingQuantityDelayed) {
        partsRemaining.status = PartStatus.DELAYED;
        partsRemaining.delayedUntil = partSplitData.data.part.delayedUntil || dayjs().add(1, 'day');
        partsRemaining.delayReason =
          partSplitData.data.part.delayReason || `Set to ${PartStatus.DELAYED.toUpperCase()} on part line split`;
      } else {
        partsRemaining.status = partSplitData.data.part.status;
      }
      splitChanges.push(partsRemaining);
      if (splitChanges && this.allowSplit) {
        this.orderedPartSplit.next({ splitChanges, splitChangeId: partSplitData.data.part.splitChangeId });
      }
    }
  }

  getOrder(id: string): Observable<Order> {
    return this.http.get(this.urlService.getUrl('ORDER_BY_ID', { ':id': id })).pipe(
      map((response: Order) => {
        // set quantity of parts group headers
        response.parts.map((part) => {
          // if part group header
          if (part.kitHeader) {
            const otherParts = response.parts.filter(
              (p) => p.partCode === part.partCode && p.uniquePartId !== part.uniquePartId
            );
            const quantity = otherParts.map((p) => p.quantity).reduce((a, b) => a + b, 0);
            if (part.quantity !== quantity) {
              part.quantity = quantity;
            }
          }
          // round net
          part.net = this.roundingService.roundValue(
            +part.net,
            this.userService.isSubscribed() ? ERoundingScheme.ROUND_UP : ERoundingScheme.AUTO
          );
          return part;
        });
        return response;
      })
    );
  }

  getOrders(query?: QueryParams): void {
    const currentPage = query.page;
    if (!query) {
      // Page is loading; use defaults
      query = new QueryParams({}, true);
    }

    const keys = Object.keys(query);
    const params = new HttpParams()
      .set(keys.at(0), query[keys.at(0)])
      .set(keys.at(1), query[keys.at(1)])
      .set(keys.at(2), query[keys.at(2)])
      .set(keys.at(3), query[keys.at(3)])
      .set(keys.at(4), query[keys.at(4)])
      .set(keys.at(5), query[keys.at(5)])
      .set(keys.at(6), query[keys.at(6)]);

    this.http
      .get(this.urlService.getUrl('ORDERS'), { params })
      .pipe(
        map((response: PagedResult) => {
          response.page = currentPage;
          this.paginationService.onResultChange(response);
          this.resultChanged$.next(response);
        })
      )
      .subscribe();
  }

  markPartDeliveryStatus(data: any): Observable<Order> {
    return this.http
      .post(this.urlService.getUrl('MARK_PART_DELIVERY_STATUS', { ':orderId': data.order }), data)
      .pipe(map((response: Order) => response));
  }

  removeSVGTooltip(target: Element): void {
    const oneSecondDelay = 100;
    const timer = setTimeout(() => {
      clearTimeout(timer);
      const parent = target?.shadowRoot.children[0].children[0];
      const child = parent?.children[0];
      if (child?.tagName === 'title') {
        parent.removeChild(child);
      }
    }, oneSecondDelay);
  }
}

import dayjs from 'dayjs';
import { Order } from '../../orders/models/order.model';
import { Quote } from '../../quotes/models/quote.model';
import { PartsService } from '../../parts/services/parts.service';
import { UtilService } from '../../shared/services/util.service';

export class OrderDataSource {
  private orderNumber?: string;
  private scheme: string;
  private status: string;
  private orderDate: dayjs.Dayjs;
  private partsDelayed?: boolean;
  private quote: Quote;
  private customerDetails: string;
  private repairerPostcode: string;
  private registration: string;
  private make: string;
  private link: string;
  private partCount: number;
  private net: number;
  private reference: string;

  constructor(order: Order) {
    this.orderNumber = order.orderNumber;
    this.status = order.status;
    this.orderDate = (order.orderedAt || '').toString().slice(0, -1) as any; // Remove the 'Z' to use local time
    this.partsDelayed = order.partsDelayed;
    this.quote = order.quote;
    this.scheme = this.quote?.quoteScheme;
    this.customerDetails = this.quote?.repairer ? this.quote?.repairer?.repairerName : 'unknown';
    this.repairerPostcode = this.quote?.repairer ? this.quote?.repairer?.address.postcode : 'unknown';
    //? Short-term solution to remove part breakdown kit header non-value 'parts' - part code by default not in order parts object
    this.partCount = order.parts.filter(p => !p.kitHeader).length;
    this.link = order._id;
    this.make = `${this.quote?.vehicle?.make} ${this.quote?.vehicle?.model}`;
    this.registration = this.quote?.vehicle?.registration;
    this.reference = order.customerOrderReferenceNumber;
  }

  setNet(net: number): void {
    //? only allow to be updated once
    if(this.net === undefined) {
      this.net = net;
    }
  }
}

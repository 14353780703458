<form [formGroup]="userForm">
  <div class="user-form">
    <div class="user-form__panel">
      <div class="user-form__user">
        <div class="user-form__user-roundel">{{userProfile?.firstName.charAt(0)}}{{userProfile?.lastName.charAt(0)}}</div>
        <div class="user-form__user-text">
          <div class="user-form__user-name-title">{{userProfile?.firstName}}&nbsp;{{userProfile?.lastName}}</div>
          <div class="user-form__user-name-container"><span class="user-form__user-name">{{groupAdminUser || siteAdminUser ? 'Admin User' : 'Standard User'}}</span></div>
          <div class="user-form__user-instructions">
            Standard Users can edit their profile and password details.
            For anything else please contact your <span>admin</span>
          </div>
        </div>
      </div>
      <div class="user-form__titles">
        <span>
          <ion-icon name="person-circle-outline"></ion-icon>User details
        </span>
      </div>
      <div class="user-form__fields">
        <div class="user-form__labelled-field user-form__labelled-field--responsive">
          <mat-label>First name</mat-label>
          <mat-form-field [class.mat-form-field-invalid]="form.firstName.errors && form.firstName.invalid" class="mat-form-field__modern mat-form-field__standard mat-form-field__standard--error mat-form-field__primary" appearance="outline">
            <input matInput name="firstName" formControlName="firstName" required type="text" placeholder="First name" />
            <mat-error
              class="has-text-danger"
              *ngIf="
                form.firstName.touched &&
                form.firstName.invalid &&
                form.firstName.errors?.required"
            >
              Your first name is required
            </mat-error>
            <mat-error
              class="has-text-danger"
              *ngIf="form.firstName.touched &&
                form.firstName.dirty &&
                form.firstName.errors?.pattern"
            >
              Your first name is invalid
            </mat-error>
          </mat-form-field>
        </div>
        <div class="user-form__labelled-field user-form__labelled-field--responsive">
          <mat-label>Last name</mat-label>
          <mat-form-field [class.mat-form-field-invalid]="form.lastName.errors && form.lastName.invalid" class="mat-form-field__modern mat-form-field__standard mat-form-field__standard--error mat-form-field__primary" appearance="outline">
            <input matInput name="lastName" formControlName="lastName" required type="text" placeholder="Last name"/>
            <mat-error
              class="has-text-danger"
              *ngIf="
                form.lastName.touched &&
                form.lastName.invalid &&
                form.lastName.errors?.required"
            >
              Your last name is required
            </mat-error>
            <mat-error
              class="has-text-danger"
              *ngIf="
                form.lastName.touched &&
                form.lastName.dirty &&
                form.lastName.errors?.pattern"
            >
              Your last name is invalid
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      
      <div class="user-form__fields">
        <div class="user-form__labelled-field user-form__labelled-field--top-margin">
          <mat-label>Email address</mat-label>
          <mat-form-field
            appearance="outline"
            class="mat-form-field__modern mat-form-field__standard mat-form-field__standard--error mat-form-field__primary mat-form-field__primary--read-only"
            matTooltip="This user already exists"
            matTooltipPosition="after"
            [class.mat-form-field-invalid]="form.email.errors && form.email.invalid"
            [class.mat-form-field__primary--read-only]="!groupAdminUser"
            [matTooltipDisabled]="!form.email.errors?.usernameExists"
          >
            <input
              matInput
              required
              formControlName="email"
              name="emailaddress"
              placeholder="Email address"
              type="email"
              [readonly]="!creatingUser"
              (blur)="checkEmailAvailability()"
            />
            <mat-error *ngIf="form.email.errors && !form.email.errors?.usernameExists && form.email.dirty">
              Your email is invalid
            </mat-error>
            <mat-error class="has-text-danger" *ngIf="form.email.errors?.usernameExists">
              This user already exists
            </mat-error>
            <mat-error class="has-text-danger" *ngIf="form.email.touched && form.email.invalid && form.email.errors?.required">
              Your email is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="user-form__panel" [hidden]="creatingUser || !editingLoggedInUser">
      <div class="user-form__titles">
        <span>
          <span>
            <ion-icon name="notifications-circle-outline"></ion-icon>
          </span>Email notifications
        </span>
        <p>Choose which <strong>email notifications</strong> you want to receive from <strong>Arcus</strong> </p>
      </div>
      <div class="user-form__fields">
        <div class="user-form__labelled-field">
          <div class="user-form__checkbox">
            <div class="user-form__labelled-field user-form__all-notifications">
              <mat-label class="user-form__labelled-margin">This is the default setting for <strong>Arcus</strong> email notifications</mat-label>
              <mat-checkbox class="mat-mdc-checkbox__primary mat-checkbox__primary-small-border" formControlName="allEmailTypes" color="primary" (change)="onEmailPreferenceChange($event, {name: 'allEmailTypes'})">
                Keep all notifications
              </mat-checkbox>
            </div>
            <mat-label>Or please select the notifications you would like to keep receiving! </mat-label>
            <!-- Quotes -->
            <div class="user-form__quote-fields">
              <div class="user-form__labelled-field">
                <mat-checkbox formControlName="quoteTypes" color="primary" (change)="onEmailPreferenceChange($event, {name: 'quoteTypes'})" class="mat-mdc-checkbox__primary mat-checkbox__primary-small-border">Quote notifications</mat-checkbox>
              </div>
              <div *ngFor="let type of quoteNotifications">
                <mat-checkbox [checked]="type.checked" color="primary" (change)="onEmailPreferenceChange($event, type)" class="mat-mdc-checkbox__primary  mat-checkbox__primary-small-border">
                  {{getEmailTypeName(type.name)}}
                </mat-checkbox>
              </div>
            </div>
            <!-- Orders -->
            <div class="user-form__order-fields">
              <div class="user-form__labelled-field">
                <mat-checkbox formControlName="orderTypes" color="primary" (change)="onEmailPreferenceChange($event, {name: 'orderTypes'})" class="mat-mdc-checkbox__primary  mat-checkbox__primary-small-border">Order notifications</mat-checkbox>
              </div>
              <div *ngFor="let type of orderNotifications">
                <mat-checkbox [checked]="type.checked" color="primary" (change)="onEmailPreferenceChange($event, type)" class="mat-mdc-checkbox__primary  mat-checkbox__primary-small-border">
                  {{getEmailTypeName(type.name)}}
                </mat-checkbox>
              </div>
            </div>
            <div class="user-form__labelled-field user-form__no-notifications">
              <mat-checkbox formControlName="noEmailTypes" color="primary" (change)="onEmailPreferenceChange($event, {name: 'noEmailTypes'})" class="mat-mdc-checkbox__primary mat-checkbox__primary-small-border">None of the above</mat-checkbox>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="user-form__panel">
      <div class="user-form__titles">
        <span>
          <ion-icon name="person-circle-outline"></ion-icon>User access
        </span>
        <p>Choose which role the user will have. Only Admins can assign user roles and sites.</p>
      </div>
      <div class="user-form__fields">
        <div class="user-form__labelled-field">
          <mat-label class="user-form__labelled-margin">User role</mat-label>
          <mat-radio-group matInput aria-label="User role" formControlName="roles" ngDefaultControl>
            <mat-radio-button
              #stdRole
              value="site_user"
              class="mat-mdc-radio-button__primary"
              [class.mat-radio-checked]="isCheckedRole(stdRole.value)"
              [class.mat-checkbox__primary-small-border--disabled]="shouldDisable('role', stdRole.value)"
              [disabled]="shouldDisable('role', stdRole.value)"
              (change)="onRoleSelectionChange(stdRole.value)"
            >
              Standard Role
            </mat-radio-button>
            <mat-radio-button
              #reportRole
              value="group_user"
              class="mat-mdc-radio-button__primary"
              [class.mat-radio-checked]="isCheckedRole(reportRole.value)"
              [class.mat-checkbox__primary-small-border--disabled]="shouldDisable('role', reportRole.value)"
              [disabled]="shouldDisable('role', reportRole.value)"
              (change)="onRoleSelectionChange(reportRole.value)"
            >
              Report Role
            </mat-radio-button>
            <mat-radio-button
              #adminRole
              value="site_admin"
              class="mat-mdc-radio-button__primary"
              [class.mat-radio-checked]="isCheckedRole(adminRole.value)"
              [class.mat-checkbox__primary-small-border--disabled]="shouldDisable('role', adminRole.value)"
              [disabled]="shouldDisable('role', adminRole.value)"
              (change)="onRoleSelectionChange(adminRole.value)"
            >
              Admin Role
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <div class="user-form__titles user-form__titles--top-margin">
        <span>
          <ion-icon name="location"></ion-icon>Group or site access
        </span>
      </div>
      <div class="user-form__fields">
        <div class="user-form__labelled-field">
          <mat-label class="user-form__labelled-margin">Access</mat-label>
          <mat-radio-group matInput aria-label="Access" formControlName="access" ngDefaultControl>
            <mat-radio-button
              #groupAccess
              *ngIf="editableUser.virtualGroups?.length"
              class="mat-mdc-radio-button__primary"
              value="Group access"
              [class.mat-radio-checked]="groupAccessSelected"
              [class.mat-checkbox__primary-small-border--disabled]="shouldDisable('access')"
              [disabled]="shouldDisable('access')"
              (change)="onAccessSelectionChange(groupAccess.value)"
            >
              Group access
            </mat-radio-button>
            <mat-radio-button
              #siteAccess
              class="mat-mdc-radio-button__primary"
              [class.mat-radio-checked]="siteAccessSelected"
              [class.mat-checkbox__primary-small-border--disabled]="shouldDisable('access')"
              [disabled]="shouldDisable('access')"
              value="Site access"
              (change)="onAccessSelectionChange(siteAccess.value)"
            >
              Site access
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <div class="associated-group" *ngIf="accessSelected">
        <div *ngIf="groupAccessSelected && editableUser.virtualGroups.length" class="user-form__titles user-form__titles--top-margin">
          <span>Please select a group the user will be associated to</span>
        </div>
        <div class="available-groups" *ngIf="groupAccessSelected && editableUser.virtualGroups.length">
          <span>Available groups</span>
          <div>
            <mat-radio-group matInput aria-label="Access" formControlName="virtualGroup" ngDefaultControl>
              <mat-radio-button
                class="mat-mdc-radio-button__primary"
                [class.mat-checkbox__primary-small-border--disabled]="shouldDisable('group-selector')"
                [class.mat-radio-checked]="virtualGroup.checked"
                [disabled]="shouldDisable('group-selector')"
                [checked]="virtualGroup.checked"
                [value]="virtualGroup.name"
                (change)="markGroupChecked(virtualGroup.email)"
                *ngFor="let virtualGroup of editableUser.virtualGroups"
              >
                {{ virtualGroup.name }}
              </mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
        <div *ngIf="groupSelected || siteAccessSelected" class="group-sites">
          <span *ngIf="groupSelected" [ngClass]="{ unselectable: !siteAccessSelected }">Available sites under the selected group the user will have access to</span>
          <span *ngIf="siteAccessSelected">Available sites</span>
          <div [ngClass]="{ flex: true, selectable: siteAccessSelected }">
            <div
              [ngClass]="{
                'centre-code-list-item': true,
                selectable: siteAccessSelected,
                unselectable: groupAccessSelected
              }"
              *ngFor="let site of editableUser.sites"
            >
              <mat-checkbox
                *ngIf="siteAccessSelected"
                class="mat-mdc-checkbox__primary "
                [class.mat-checkbox__primary-small-border--disabled]="shouldDisable('site-selector')"
                [checked]="isCheckedSite(site.name)"
                [disabled]="shouldDisable('site-selector')"
                (change)="onSiteSelectionChange($event, site.centreCode)"
              >
                <span>{{ site.name }}</span>
              </mat-checkbox>
              <span *ngIf="groupSelected">{{ site.name }}</span>
              <span *ngIf="groupSelected">{{ site.centreCode }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="user-form__panel" [hidden]="creatingUser">
      <div class="user-form__titles">
        <span>
          <ion-icon name="person-remove-outline"></ion-icon>Disabling a user
        </span>
        <p>Disabling a user will pause their account until you are ready to enable.
          Disabled accounts will be deleted after a period of time.</p>
      </div>
      <div class="user-form__fields">
        <div class="user-form__labelled-field">
          <mat-radio-group aria-label="Disable user" formControlName="enabled" (change)="toggleEnableUser()" ngDefaultControl>
            <mat-radio-button [value]="false" class="mat-mdc-radio-button__primary" [disabled]="isUserEnabled()" [class.mat-checkbox__primary-small-border--disabled]="isUserEnabled()">
              Disable user
            </mat-radio-button>
            <mat-radio-button [value]="true" class="mat-mdc-radio-button__primary" [disabled]="isUserEnabled()" [class.mat-checkbox__primary-small-border--disabled]="isUserEnabled()">
              Enable user
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
    </div>
    <div class="user-form__panel" [hidden]="creatingUser">
      <div class="user-form__titles">
        <span>
          <span>
            <ion-icon name="refresh" class="user-form__overlay-icon"></ion-icon>
            <ion-icon name="lock-closed" class="user-form__underlay-icon"></ion-icon>
          </span>Reset password
        </span>
        <p>A password reset link will be sent to the registered email address </p>
      </div>
      <div class="user-form__fields">
        <div class="user-form__labelled-field">
          <mat-label class="user-form__labelled-margin">Reset user password</mat-label>
          <mat-checkbox formControlName="resetPassword" color="primary" (change)="toggleResetPassword($event)" class="mat-mdc-checkbox__primary mat-mdc-checkbox__primary-small-border">
            Yes please reset password
          </mat-checkbox>
        </div>
      </div>
    </div>
    <div class="user-form__action-buttons">
      <button mat-stroked-button class="mat-stroked-button__primary" (click)="closeSideBar()">Cancel</button>
      <button mat-flat-button class="mat-mdc-unelevated-button__primary" [disabled]="!shouldSubmitForm()" *ngIf="creatingUser" (click)="createUser()">Create new user</button>
      <button mat-flat-button class="mat-mdc-unelevated-button__primary" [disabled]="!shouldSubmitForm()" *ngIf="!creatingUser" (click)="updateUser()">Update details</button>
    </div>
  </div>
</form>

import { Component, EventEmitter, Output, AfterViewInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { PagedResult } from './paging.consts';
import { PaginationService } from '../../services/pagination.service';

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss']
})
export class PaginatorComponent implements AfterViewInit, OnChanges {
  @Input() currentPage: number;
  @Input() maxPageResults: number;

  @Output() maxPageResultsChanged: EventEmitter<number> = new EventEmitter<number>();
  @Output() pageRequested: EventEmitter<string> = new EventEmitter<string>();

  firstPage = 1;
  firstResult = 1;
  invalidPage = false;
  lastPage = 1;
  lastResult = 10;
  maxPageResultsSelector = [{ resultCount: 5 }, { resultCount: 10 }, { resultCount: 15 }, { resultCount: 20 }];

  loaded = false;
  page = '';
  pagesInCounter: string[];
  results: any[];
  resultTotal = 1;

  constructor(private readonly paginationService: PaginationService) {}

  ngAfterViewInit(): void {
    this.paginationService.resultChanged$.subscribe((response: PagedResult) => {
      this.currentPage = this.currentPage || 1;
      this.firstResult = (this.currentPage - 1) * this.maxPageResults + 1;
      const lastResult = this.firstResult + this.maxPageResults - 1;
      this.results = response.results;
      this.resultTotal = response.resultTotal;
      this.lastPage = response.lastPage;
      this.lastResult = lastResult > this.resultTotal ? this.resultTotal : lastResult;
      this.pagesInCounter = this.getPagesInCounter();
      this.loaded = true;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.currentPage) {
      this.page = this.currentPage.toString();
    }
  }

  goToPage(page: string): boolean {
    const validPage = !isNaN(+page);
    const notCurrentPage = +page !== this.currentPage;
    const withinValidRange = +page >= this.firstPage && +page <= this.lastPage;

    if (validPage && notCurrentPage && withinValidRange) {
      this.currentPage = +page;
      this.pageRequested.emit(page);
      return true;
    }

    return false;
  }

  onResultCountChange(event: any): void {
    const selectedMaxPageResults = this.maxPageResultsSelector.at(event.target.value);
    this.currentPage = 1;
    this.maxPageResultsChanged.emit(selectedMaxPageResults.resultCount);
  }

  trackByFn(index: number): number {
    return index;
  }

  private addToPageCounter(counter: string[], startAt: number, endAt: number, item = ''): string[] {
    let i = startAt;
    while (i < endAt) {
      counter.push(i.toString());
      i += 1;
    }

    if (item) {
      counter.push(item);
    }

    return counter;
  }

  private getPagesInCounter(): string[] {
    const maxItemsInCounter = 9;
    const maxPageNumberBlock = 5; // Maximum consecutive page numbers
    const ellipsis = '...';
    const blockStart = 4;
    const blockEnd = 9;
    let counter = [];
    const addTrailingEllipsisAndLastPage = () => {
      counter = this.addToPageCounter(counter, 1, 1, ellipsis);
      counter = this.addToPageCounter(counter, this.lastPage, this.lastPage + 1);
    };

    // First page
    counter = this.addToPageCounter(counter, 1, 2);

    if (this.lastPage <= maxItemsInCounter) {
      // Unbroken page counter
      counter = this.addToPageCounter(counter, 2, this.lastPage + 1);
    } else if (this.currentPage < blockStart) {
      counter = this.addToPageCounter(counter, 2, maxPageNumberBlock + 2);
      addTrailingEllipsisAndLastPage();
    } else if (this.currentPage < maxPageNumberBlock + 2) {
      // Leading ellipsis
      counter = this.addToPageCounter(counter, 1, 1, ellipsis);
      counter = this.addToPageCounter(counter, blockStart, blockEnd);
      addTrailingEllipsisAndLastPage();
    } else if (this.currentPage > maxPageNumberBlock && this.currentPage + maxPageNumberBlock <= this.lastPage) {
      // Leading ellipsis
      counter = this.addToPageCounter(counter, 1, 1, ellipsis);
      counter = this.addToPageCounter(counter, this.currentPage - 2, this.currentPage + 3);
      addTrailingEllipsisAndLastPage();
    } else {
      // Leading ellipsis
      counter = this.addToPageCounter(counter, 1, 1, ellipsis);
      counter = this.addToPageCounter(counter, this.lastPage - maxPageNumberBlock - 1, this.lastPage + 1);
    }

    return counter;
  }
}

<div
    routerLink="{{notification.url}}"
    routerLinkActive="router-link-active"
    (click)="cardViewed(notification._id)"
    *ngIf="notification !== undefined" 
    class="notification-card"
    [class.notification-card__older]="compareAgeing('ANCIENT')"
    [class.notification-card__default]="compareAgeing('WEEKONE') || compareAgeing('WEEKTWO')"
>
    <ion-icon 
    class="close-btn"
    [class.close-btn--left]="checkAlignment('left')"
    [class.close-btn--right]="checkAlignment('right')"
    name="close"
    id="{{notification._id}}"
    matTooltip="Remove notification"
    (click)="dismiss($event)"
    ></ion-icon>
    <div class="notification-card__row">
        <div class="notification-card__column">
            <app-order-type-badge *ngIf="showScheme" [scheme]="scheme ?? newScheme"></app-order-type-badge>
            <span class="notification-card__column--title">
                {{title}}</span>
        </div>
        <div class="notification-card__column">
            <ion-icon class="notification-card__column--time-icon" name="time-outline"></ion-icon>
            <span class="notification-card__column--body">{{ date }}</span>
            <!-- | date:'MMM d' -->
            <ion-icon 
                *ngIf=" compareAgeing('WEEKTWO') || compareAgeing('ANCIENT')"
                class="notification-card__column--warning-icon" 
                name="warning"></ion-icon>
        </div>
    </div>
    <div class="notification-card__row">
        <div class="notification-card__column">
            <span class="notification-card__column--body2">
                {{bodyText}}
                <app-status-badge *ngIf="notification?.registration!==''&& notification?.registration!==undefined && notification?.registration!==null"
                    [customText]="notification.registration" [status]="customStatus"
                >
                </app-status-badge>
            </span>
        </div>
        <div class="notification-card__column">
            <span 
                *ngIf="viewedBy"
                [libTooltip]="viewedBy"
                class="notification-card__column--avatar"
            >
                {{ viewedByAbbr }}
            </span>
            <app-status-badge 
                [status]="status ? status : newStatus"
            >
            </app-status-badge>
        </div>
    </div>
</div>

